<template>
    <div>
        <div class="m-signature-pad--body">
            <canvas width="521" height="261"></canvas>
        </div>
        <div class="m-signature-pad--footer">
            <div class="save pull-right d-flex justify-content-between align-items-center">
                <p class="text-white mb-0 pr-2" v-show="signaturePadEmpty">Please sign before saving</p>
                <button type="button" class="btn btn-primary button" @click.prevent="saveSignature">Save</button>
            </div>
            <button type="button" class="btn btn-grey button clear" @click.prevent="clearSignature">Clear and redo</button>
            <button type="button" class="btn btn-default modal-close btn-grey" @click.prevent="closeModal">Cancel</button>
        </div>
    </div>
</template>

<script>
    import SignaturePad from 'signature_pad';

    export default {
        mounted() {
            let that = this;
            var canvas = document.querySelector("canvas");

            that.signaturePad = new SignaturePad(canvas, {
                penColor: "rgb(0, 0, 0)",
                backgroundColor: "rgb(255,255,255)",
            });
        },
        data() {
            return {
                signature: '',
                signaturePad: null,
                signaturePadEmpty: false,
            }
        },
        methods: {
            clearSignature: function() {
                this.signaturePad.clear();
            },
            closeModal: function() {
                this.$emit('closeModalClicked');
            },
            saveSignature: function() {
                if(this.signaturePad.isEmpty()) {
                    this.signaturePadEmpty = true;
                    return;
                }
                this.signature = this.signaturePad.toDataURL("image/jpeg");
                this.$emit('signatureSave', this.signature);
            }
        }
    }
</script>
